.App {
  text-align: center;
}

header {
  background-color: #333;
  padding: 5px 20px;
}

header nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.desktop-menu ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
}

.desktop-menu ul li {
  margin: 0 15px 0 0;
}

.desktop-menu ul li a {
  color: white;
  text-decoration: none;
  font-size: 0.9em;
}

.mobile-menu {
  display: none;
}

.hamburger-button {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  display: none;
}

.typeform-button {
  margin-left: auto;
}

/* react-burger-menu styles */
.bm-burger-button {
  display: none;
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-menu-wrap {
  top: 0;
}

.bm-item-list {
  padding: 0.8em;
  display: flex;
  flex-direction: column;
}

.bm-item {
  display: block;
  padding: 15px 0;
  font-size: 1.2em;
  text-align: left;
  color: #b8b7ad;
  text-decoration: none;
  transition: color 0.3s;
  border-bottom: 1px solid #4a4a4a;
  margin-bottom: 10px;
}

.bm-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.bm-item:hover {
  color: #ffffff;
}

.main-container {
  background-color: black;
  color: white;
  padding: 20px;
  min-height: calc(100vh - 120px); /* Adjusted to account for header, footer, and reduced spacing */
}

main {
  padding-bottom: 20px;
}

.call-to-action {
  background-color: #333;
  color: white;
  padding: 30px 20px; /* Reduced top and bottom padding */
  margin-top: 20px; /* Reduced top margin */
}

.call-to-action h2 {
  margin-bottom: 20px;
  color: white;
}

footer {
  background-color: #333;
  color: white;
  padding: 20px;
  margin-top: 20px; /* Reduced top margin */
}

@media (max-width: 768px) {
  .desktop-menu {
    display: none;
  }

  .mobile-menu {
    display: block;
  }

  .hamburger-button {
    display: block;
  }

  header nav {
    justify-content: space-between;
  }

  .typeform-button {
    margin-left: 0;
  }

  .bm-menu {
    width: 250px;
  }

  .bm-item {
    padding: 15px;
    text-align: center;
  }
}

@media (max-width: 480px) {
  header {
    padding: 5px 10px;
  }

  .typeform-button {
    font-size: 0.8em;
  }
}