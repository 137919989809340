@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');

.protected-story {
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
  font-family: 'Koulen', sans-serif;
}

.story-title {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.story-container {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  width: 100%;
  text-align: center;
}

.content h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.content p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.password-input {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;
  width: 80%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.password-input input {
  width: 100%;
  padding: 12px;
  font-size: 1.1em;
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #000;
  color: #fff;
  padding-right: 40px;
}

.toggle-password {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 30px;
  height: 20px;
  overflow: hidden;
}

.eyelid {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 0 0 50% 50%;
  transition: all 0.3s ease;
}

.toggle-password.open .eyelid {
  transform: translateY(-100%);
}

.submit-button {
  background-color: #567C93;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'Koulen', sans-serif;
}

.submit-button:hover {
  background-color: #567C93;
}

.typeform-container {
  margin-top: 30px;
}

@media (max-width: 768px) {
  .story-container {
    width: 95%;
    padding: 10px;
  }

  .story-title {
    font-size: 1.8rem;
  }

  .content h2 {
    font-size: 1.5em;
  }

  .password-input {
    width: 90%;
  }

  .submit-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .story-container {
    width: 100%;
  }

  .story-title {
    font-size: 1.6rem;
  }

  .content h2 {
    font-size: 1.3em;
  }

  .content p {
    font-size: 1em;
  }

  .password-input {
    width: 100%;
  }

  .submit-button {
    padding: 8px 16px;
    font-size: 12px;
  }
}