@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');

.story-page {
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
  font-family: 'Koulen', sans-serif;
}

.story-title {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.story-container {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab-navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
}

.tab-navigation button {
  padding: 10px 20px;
  margin: 0 5px;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-family: 'Koulen', sans-serif;
  font-size: 1.2rem;
  flex: 1;
  white-space: nowrap;
}

.tab-navigation button.active {
  background-color: black;
  color: white;
}

.content-viewer {
  width: 100%;
  height: 0;
  padding-bottom: 75%; /* 4:3 Aspect Ratio */
  position: relative;
  overflow: hidden;
}

.story-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.commentary-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  font-family: 'Koulen', sans-serif;
}

.commentary-content h2 {
  font-size: 1.8em;
  margin-bottom: 15px;
}

.commentary-content p {
  font-size: 1.2em;
  line-height: 1.6;
  white-space: pre-wrap;
}

.arrow {
  font-size: 24px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'Koulen', sans-serif;
}

.left-arrow {
  left: 20px;
}

.right-arrow {
  right: 20px;
}

.page-number {
  margin-top: 10px;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .story-page {
    padding: 10px;
  }

  .story-title {
    font-size: 1.8rem;
  }

  .story-container {
    width: 95%;
    padding: 10px;
  }

  .tab-navigation button {
    padding: 8px 15px;
    font-size: 1rem;
  }

  .commentary-content h2 {
    font-size: 1.5em;
  }

  .commentary-content p {
    font-size: 1em;
  }

  .arrow {
    font-size: 1.5rem;
  }

  .page-number {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .story-container {
    width: 100%;
  }

  .story-title {
    font-size: 1.6rem;
  }

  .tab-navigation button {
    padding: 5px 10px;
    font-size: 0.9rem;
  }

  .commentary-content h2 {
    font-size: 1.3em;
  }

  .commentary-content p {
    font-size: 0.9em;
  }

  .arrow {
    font-size: 1.2rem;
  }

  .page-number {
    font-size: 0.7rem;
  }
}