.app-header {
    background-color: #000000;
    padding: 10px 20px;
    position: sticky;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header-logo {
    width: 100px;
    height: auto;
  }
  
  .app-header nav {
    display: flex;
  }
  
  .app-header nav a {
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    margin: 0 10px;
    font-family: 'Koulen', sans-serif;
    font-size: 1.2rem;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .app-header nav a:hover {
    background-color: #B582A1;
  }
  
  .hamburger-button {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .app-header {
      flex-wrap: wrap;
    }
  
    .header-logo {
      margin-right: auto;
    }
  
    .hamburger-button {
      display: block;
    }
  
    .app-header nav {
      flex-direction: column;
      width: 100%;
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      background-color: #000000;
    }
  
    .app-header nav.open {
      display: flex;
    }
  
    .app-header nav a {
      margin: 0;
      padding: 15px 20px;
      border-top: 1px solid #333;
    }
  }