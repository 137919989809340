@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');

.kyotish-dictionary-page {
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
  font-family: 'Koulen', sans-serif;
}

.dictionary-title {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

.kyotish-dictionary-container {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
}

.tab {
  padding: 10px 20px;
  margin: 0 5px;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-family: 'Koulen', sans-serif;
  font-size: 1.2rem;
  flex: 1;
  text-align: center;
}

.tab.active {
  background-color: black;
  color: white;
}

.translator {
  width: 100%;
}

.translation-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.input-area, .output-area {
  width: 45%;
  margin-bottom: 20px;
}

textarea, .kyotish-text {
  width: 100%;
  height: 300px;
  padding: 15px;
  border: 1px solid #aaa;
  border-radius: 4px;
  resize: none;
  font-size: 18px;
  font-family: 'Koulen', sans-serif;
  line-height: 1.5;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  box-sizing: border-box;
}

textarea {
  color: black;
  background-color: #f9f9f9;
}

.kyotish-text {
  color: white;
  background-color: transparent;
  overflow-y: auto;
}

.kyotish-text span {
  background-color: transparent !important;
}

.character-count {
  text-align: right;
  color: white;
  font-size: 0.8em;
  margin-top: 5px;
}

.alphabet h2 {
  color: white;
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
}

.alphabet-grid {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(6, 1fr);
  width: 100%;
}

.alphabet-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #eee;
  border-radius: 4px;
}

.english-char,
.kyotish-char {
  font-size: 20px;
  margin-bottom: 5px;
  color: black;
}

.star-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 100px;
  height: auto;
}

.download-btn {
  background-color: #567C93;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'Koulen', sans-serif;
}

.download-btn:hover {
  background-color: #567C93;
  
}

@media (max-width: 768px) {
  .kyotish-dictionary-page {
    padding: 10px;
  }

  .dictionary-title {
    font-size: 1.8rem;
    margin-bottom: 0.3rem;
  }

  .kyotish-dictionary-container {
    width: 95%;
    padding: 10px;
  }

  .translation-container {
    flex-direction: column;
  }

  .input-area, .output-area {
    width: 100%;
  }

  .tab {
    padding: 8px 15px;
    font-size: 1rem;
  }

  textarea, .kyotish-text {
    font-size: 16px;
    height: 250px;
  }

  .alphabet-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .english-char,
  .kyotish-char {
    font-size: 16px;
  }

  .star-icon {
    width: 60px;
    bottom: 10px;
    right: 10px;
  }
}

@media (max-width: 480px) {
  .dictionary-title {
    font-size: 1.6rem;
  }

  .tab {
    padding: 5px 10px;
    font-size: 0.9rem;
  }

  textarea, .kyotish-text {
    font-size: 14px;
    height: 200px;
  }

  .alphabet-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  .english-char,
  .kyotish-char {
    font-size: 14px;
  }

  .star-icon {
    width: 40px;
  }
}
.kyotish-text {
  color: white;
  background-color: transparent;
  overflow-y: auto;
  border: none; /* Remove the border */
  outline: none; /* Remove any outline */
}

.kyotish-text span {
  background-color: transparent !important;
  border: none; /* Ensure no border on individual spans */
  outline: none; /* Ensure no outline on individual spans */
}